
<template>
  <!-- 会员权益体系 -->
  <div>
    <div class="heading">
      <h3>构建企业级的用户画像体系</h3>
      <p>
        用户画像是对多维度海量用户数据进行整理和分析后形成的精准用户洞察。我们都想知道客户究竟是什么样的人，应用DM
        Hub完整还原每一位真实客户。
      </p>
      <button @click="onapply()">申请试用</button>
    </div>
    <div class="summary">
      <h5>全渠道用户数据汇总</h5>
      <div class="summary_n">
        <div
          class="summary_c"
          v-for="(v, index) in summarylist"
          :key="index"
        >
          <h6>{{ v.title }}</h6>
          <p>
            {{ v.content }}
          </p>
        </div>
      </div>
    </div>
    <div class="content">
      <img src="../../assets/images/member01.png" />
      <div>
        <h6>用户数据治理</h6>
        <p>
          ·实时处理能力: 亿级海量数据的稳定计算能力<br />
          ·数据清洗: 异常数据过滤、脏数据清洗和较验<br />
          ·数据合并: 自动化的ID Mapping和One-ID体系<br />
          ·数据分析: AI Hub人群聚类智能分析和模型验算
        </p>
        <button @click="onapply()">免费试用</button>
      </div>
    </div>
    <div class="portrait">
      <img src="../../assets/images/summary_c.png" alt="" />
    </div>
    <div class="content">
      <div>
        <h6>形成用户画像</h6>
        <p>
          ·建立标签体系: 建立体系化的客户标签和内容标签体系<br />
          ·精准客户分层: 通过客户画像背后的多级维度，智能化圈群分组<br />
          ·构建用户画像: 通过多维度指标输出精准360°客户画像
        </p>
        <button @click="onapply()">免费试用</button>
      </div>
      <img src="../../assets/images/member02.png" />
    </div>
    <div class="content">
      <div>
        <h6>用户画像的维度</h6>
        <p>
          ·用户来源: 用户数据来源可以精确到来源平台、媒体、创意、广告位、内容等渠道<br />
          ·用户属性: 用户的人口学属性和具有唯一性的可识别属性(性别、年龄、人生阶段等)<br />
          ·用户身份: 身份信息、微信OpenID、手机IMEI、DeviceID、Email地址和第三方ID<br />
          ·用户标签: 标签是最核心的部分，DM
          Hub具备自动化标签流程和各行业标签模板库<br />
          ·内容标签: 用户所触达的营销及内容偏好的标记，实现对相同偏好人群的分层<br />
          ·用户行为: 可自定义的用户事件，如浏览点击、注册、交互、交易购买等行为
        </p>
        <button @click="onapply()">免费试用</button>
      </div>
      <img src="../../assets/images/member03.png" />
    </div>
    <div class="cost">
      <h4 class="head">用户画像的营销应用</h4>
      <div class="cost_c">
        <div>
          <img src="../../assets/images/portrait01.png" />
          <h6>精准营销</h6>
          <p>有效支持千人千面的个性化精准营销</p>
        </div>
        <div>
          <img src="../../assets/images/portrait02.png" />
          <h6>DMP人群包</h6>
          <p>输出精准的广告投放DMP人群包</p>
        </div>
        <div>
          <img src="../../assets/images/portrait03.png" />
          <h6>One ID</h6>
          <p>多渠道重复客户归并，避免重复营销触达</p>
        </div>
        <div>
          <img src="../../assets/images/portrait04.png" />
          <h6>商业BI分析</h6>
          <p>可视化数据洞察，有效掌握业务全景</p>
        </div>
      </div>
    </div>
    <div class="foot">
      <h6>500＋企业的共同选择</h6>
      <p>千人千面的策略布置</p>
      <img src="../../assets/images/hzqy.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["childEvent"],
  data() {
    return {
      summarylist: [
        {
          title: "第一方数据",
          content:
            "打通网站、微信、小程序、App、H5、商品互动码、POS、短信、邮件、CRM、会员系统、线下活动等渠道",
        },
        {
          title: "第二方数据",
          content:
            "来自于社交媒体、电商平台、营销活动网站、客户调研等数据来源，但数据往往存在于其他渠道平台",
        },
        {
          title: "第三方数据",
          content:
            "包括各类线下数据、DMP数据包、第三方媒体数据等，可通过API调用或导入的方式来应用",
        },
      ],
      summary: 0,
    };
  },
  created() {},
  mounted() {},
  methods: {
    onsummary(i) {
      this.summary = i;
    },
    onapply() {
      // this.childEvent(true);
      window.location.href = "http://xdnmxba7z7d6llfo.mikecrm.com/uMx7QGf";
    },
  },
};
</script>

<style scoped lang="less">
.heading {
  height: 6.6rem;
  background-image: url(../../assets/images/digitization06.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  h3 {
    font-size: 0.56rem;
    padding-top: 2.05rem;
  }
  p {
    text-align: left;
    width: 7.43rem;
    margin: 0.18rem auto;
    font-size: 0.2rem;
    line-height: 0.3rem;
  }
  button {
    width: 1.48rem;
    height: 0.64rem;
    background-color: #ffc53d;
    border: 0;
    color: #fff;
    font-size: 0.2rem;
    margin-top: 0.4rem;
    border-radius: 0.05rem;
  }
}
.summary {
  padding: 1.36rem 2.2rem 0;
  color: #333;
  h5 {
    font-size: 0.28rem;
    line-height: 0.5rem;
    text-align: center;
    padding-bottom: 0.1rem;
  }
  .summary_n {
    padding-top: 0.58rem;
    display: flex;
    justify-content: space-between;
    .summary_c {
      width: 4.8rem;
      height: 1.98rem;
      box-sizing: border-box;
      padding: 0.32rem 0.19rem 0;
      transition: all 1.5s;
      h6 {
        font-size: 0.22rem;
        text-align: center;
        padding-bottom: 0.24rem;
      }
      p {
        color: #666;
        font-size: 0.18rem;
        line-height: 0.27rem;
      }
    }
    .summary_c:hover {
      box-shadow: 10px 10px 30px 1px rgba(0, 0, 115, 0.13);
      border-radius: 0.1rem;
    }
  }
}
.content {
  display: flex;
  justify-content: center;
  padding: 0.68rem 0;
  //   background-color: #fffff5;
  img {
    width: 7.08rem;
    height: 4.38rem;
  }
  div {
    width: 6.62rem;
    padding-left: 0.88rem;
    padding-top: 0.38rem;
    h6 {
      font-size: 0.28rem;
      line-height: 0.42rem;
      color: #333;
      padding-bottom: 0.32rem;
    }
    p {
      font-size: 0.18rem;
      color: #666;
      padding-bottom: 0.12rem;
      line-height: 0.27rem;
    }
    button {
      width: 1.48rem;
      height: 0.64rem;
      background-color: #333;
      color: #ffc53d;
      border-radius: 0.05rem;
    }
  }
}
.content:nth-child(6) {
  background-color: #fffff5;
}
.portrait {
  text-align: center;
  img {
    width: 14.4rem;
    height: 5.7rem;
  }
}
.cost {
  padding: 1rem 0 1.2rem;
  .head {
    font-size: 0.28rem;
    line-height: 0.5rem;
    text-align: center;
    padding-bottom: 0.1rem;
  }
  .cost_c {
    padding: 0.58rem 1.5rem 0;
    display: flex;
    justify-content: space-between;
    div {
      width: 4rem;
      text-align: center;
      img {
        width: 0.8rem;
        height: 0.8rem;
      }
      h6 {
        padding-top: 0.2rem;
        font-size: 0.22rem;
        padding-bottom: 0.16rem;
      }
      p {
        font-size: 0.18rem;
        line-height: 0.27rem;
        color: #666;
      }
    }
  }
}
.foot {
  h6 {
    font-size: 0.28rem;
    text-align: center;
    padding-bottom: 0.2rem;
  }
  p {
    font-size: 0.18rem;
    color: #666;
    text-align: center;
  }
  img {
    width: 19.2rem;
    height: 7.88rem;
  }
}
</style>
