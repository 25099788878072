var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"heading"},[_c('h3',[_vm._v("构建企业级的用户画像体系")]),_c('p',[_vm._v(" 用户画像是对多维度海量用户数据进行整理和分析后形成的精准用户洞察。我们都想知道客户究竟是什么样的人，应用DM Hub完整还原每一位真实客户。 ")]),_c('button',{on:{"click":function($event){return _vm.onapply()}}},[_vm._v("申请试用")])]),_c('div',{staticClass:"summary"},[_c('h5',[_vm._v("全渠道用户数据汇总")]),_c('div',{staticClass:"summary_n"},_vm._l((_vm.summarylist),function(v,index){return _c('div',{key:index,staticClass:"summary_c"},[_c('h6',[_vm._v(_vm._s(v.title))]),_c('p',[_vm._v(" "+_vm._s(v.content)+" ")])])}),0)]),_c('div',{staticClass:"content"},[_c('img',{attrs:{"src":require("../../assets/images/member01.png")}}),_c('div',[_c('h6',[_vm._v("用户数据治理")]),_vm._m(0),_c('button',{on:{"click":function($event){return _vm.onapply()}}},[_vm._v("免费试用")])])]),_vm._m(1),_c('div',{staticClass:"content"},[_c('div',[_c('h6',[_vm._v("形成用户画像")]),_vm._m(2),_c('button',{on:{"click":function($event){return _vm.onapply()}}},[_vm._v("免费试用")])]),_c('img',{attrs:{"src":require("../../assets/images/member02.png")}})]),_c('div',{staticClass:"content"},[_c('div',[_c('h6',[_vm._v("用户画像的维度")]),_vm._m(3),_c('button',{on:{"click":function($event){return _vm.onapply()}}},[_vm._v("免费试用")])]),_c('img',{attrs:{"src":require("../../assets/images/member03.png")}})]),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" ·实时处理能力: 亿级海量数据的稳定计算能力"),_c('br'),_vm._v(" ·数据清洗: 异常数据过滤、脏数据清洗和较验"),_c('br'),_vm._v(" ·数据合并: 自动化的ID Mapping和One-ID体系"),_c('br'),_vm._v(" ·数据分析: AI Hub人群聚类智能分析和模型验算 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"portrait"},[_c('img',{attrs:{"src":require("../../assets/images/summary_c.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" ·建立标签体系: 建立体系化的客户标签和内容标签体系"),_c('br'),_vm._v(" ·精准客户分层: 通过客户画像背后的多级维度，智能化圈群分组"),_c('br'),_vm._v(" ·构建用户画像: 通过多维度指标输出精准360°客户画像 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" ·用户来源: 用户数据来源可以精确到来源平台、媒体、创意、广告位、内容等渠道"),_c('br'),_vm._v(" ·用户属性: 用户的人口学属性和具有唯一性的可识别属性(性别、年龄、人生阶段等)"),_c('br'),_vm._v(" ·用户身份: 身份信息、微信OpenID、手机IMEI、DeviceID、Email地址和第三方ID"),_c('br'),_vm._v(" ·用户标签: 标签是最核心的部分，DM Hub具备自动化标签流程和各行业标签模板库"),_c('br'),_vm._v(" ·内容标签: 用户所触达的营销及内容偏好的标记，实现对相同偏好人群的分层"),_c('br'),_vm._v(" ·用户行为: 可自定义的用户事件，如浏览点击、注册、交互、交易购买等行为 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cost"},[_c('h4',{staticClass:"head"},[_vm._v("用户画像的营销应用")]),_c('div',{staticClass:"cost_c"},[_c('div',[_c('img',{attrs:{"src":require("../../assets/images/portrait01.png")}}),_c('h6',[_vm._v("精准营销")]),_c('p',[_vm._v("有效支持千人千面的个性化精准营销")])]),_c('div',[_c('img',{attrs:{"src":require("../../assets/images/portrait02.png")}}),_c('h6',[_vm._v("DMP人群包")]),_c('p',[_vm._v("输出精准的广告投放DMP人群包")])]),_c('div',[_c('img',{attrs:{"src":require("../../assets/images/portrait03.png")}}),_c('h6',[_vm._v("One ID")]),_c('p',[_vm._v("多渠道重复客户归并，避免重复营销触达")])]),_c('div',[_c('img',{attrs:{"src":require("../../assets/images/portrait04.png")}}),_c('h6',[_vm._v("商业BI分析")]),_c('p',[_vm._v("可视化数据洞察，有效掌握业务全景")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot"},[_c('h6',[_vm._v("500＋企业的共同选择")]),_c('p',[_vm._v("千人千面的策略布置")]),_c('img',{attrs:{"src":require("../../assets/images/hzqy.png"),"alt":""}})])
}]

export { render, staticRenderFns }